<!--
Display an input to take an embed code and create S2Video object
-->
<template>
  <div>
    <h2>Create Embed</h2>
    <b-form-input v-model="embedUrl" placeholder="Embed URL"></b-form-input>
    <b-input-group v-if="isValidEmbedUrl" class="mt-2">
      <b-input-group-prepend>
        <b-input-group-text>
          Name:
        </b-input-group-text>
      </b-input-group-prepend>
      <b-input v-model="name" />
    </b-input-group>
    <b-button class="mt-2" @click="create" :disabled="!isValidEmbedUrl">Create</b-button>
    <div v-if="link" class="mt-2">
      <b-alert variant="success" :show="true">
        Embed created successfully.
      </b-alert>
      <shareable :content="link" />
    </div>    
    <div v-if="isValidEmbedUrl">
      <div class="mt-3">Preview</div>
      <iframe ref="embed" :src="embedUrl" frameborder="0" allowfullscreen="true"
        mozallowfullscreen="true" webkitallowfullscreen="true"/>
    </div>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('create-embed');
import { db } from '@/services/db';
import { randomString } from '@/services/utils';
import { getUniqueLink, createLinkObject, getLinkUrl } from '@/services/links';

export default {
  data() {
    return {
      embedUrl: "",
      url: null,
      name: "Untitled",
      link: null,
    }
  },
  computed: {
    // starts with http
    isValidEmbedUrl() {
      return this.embedUrl.startsWith("http");
    }
  },
  methods: {
    create() {
      let assetId = randomString(32);
      let data = {
        name: this.name,
        url: this.embedUrl,
        type: "embed",
        ownerId: this.$store.account.uid,
        filename: this.name,
        created: new Date(),
      }
      db.collection('S2Videos').doc(assetId).set(data);
      data.id = assetId;
      this.link = this.createLink(data);

    },
    async createLink(asset) {
      log.log("createLink");
      let linkId = getUniqueLink("belong-", true);
      let link = createLinkObject(asset, linkId, "default");
      return getLinkUrl(link.id);
    },
  }
}
</script>
